import { Add_product_text } from '../constant/Translation/AddProduct_text';
import { dynamic_translation_set } from '../constant/Translation/translation_set';

// Attribute checking
export const validate_attribute_data = ({
  attributes,
  attributeData,
  setShowError,
  setError_message,
}) => {
  for (let i = 0; i < attributes.length; i++) {
    const attribute = attributes[i];
    if (!attribute.optional) {
      if (
        !attributeData?.filter((at_data) => at_data.id === attribute.id)
          ?.length > 0 ||
        attributeData === null
      ) {
        setShowError(true);
        setError_message(
          dynamic_translation_set(
            Add_product_text.addproduct.alert_attribute_required,
            attribute.name
          )
        );
        return false;
        break;
      }
    }
  }
};
